<template>
  <div class="thank-you-page">
    <v-card class="elevation-0">
      <CategoryTitle :category="category" />
      <v-card-text>
        <v-form
          ref="form"
          class="reset-password"
          v-model="valid"
          :lazy-validation="lazy"
        >
          <v-text-field
            v-if="false"
            v-model="email"
            label="Indirizzo email *"
            required
            outlined
            :rules="emailRules"
            dense
            :error-messages="errors"
          ></v-text-field>

          <v-text-field
            v-model="password"
            label="Password *"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="passwordConfirm"
            label="Conferma Password *"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordConfirmRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
            clearable
          ></v-text-field>
        </v-form>
        <ResponseMessage :response="response" />
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          @click="handleResetPassword"
          class="reset-button"
          color="primary"
          large
          depressed
          :disabled="!valid"
          >{{ $t("login.submitResetPassword") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>
<style scoped>
.reset-password {
  max-width: 350px;
}
</style>
<script>
import RegistrationService from "@/service/iperalCustomService";
import ResponseMessage from "@/components/ResponseMessage";
import CategoryTitle from "@/components/category/CategoryTitle";
import categoryMixin from "~/mixins/category";
import { mapActions } from "vuex";

import {
  requiredValue,
  isEmail,
  isPasswordMinimumLengthIperal
} from "@/validator/validationRules";

export default {
  name: "ResetPassword",
  components: { ResponseMessage, CategoryTitle },
  mixins: [categoryMixin],
  data() {
    return {
      email: "",
      showPassword: false,
      password: null,
      passwordConfirm: null,
      valid: true,
      lazy: true,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      passwordRules: [
        requiredValue("Digitare la password"),
        isPasswordMinimumLengthIperal()
      ],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        isPasswordMinimumLengthIperal(),
        v => v === this.password || "Le password non coincidono"
      ],
      errors: [],
      invalid: null,
      token: null,
      response: {},
      loginEmail: null,
      showForm: true
    };
  },
  methods: {
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    ...mapActions({ loadCart: "cart/loadCart" }),
    async handleResetPassword() {
      try {
        this.loading = true;
        const response = await RegistrationService.resetPassword(
          this.token,
          this.password
        );
        this.response = response;
        if (response.response.status == 0) {
          //this.loadCart();
          this.$router.push({ name: "ResetPasswordCompleted" });
        }
      } catch (err) {
        if (err.response) {
          this.response = err.response.data.response;
        }
      } finally {
        this.loading = false;
        this.showForm = false;
      }
    },
    getQueryParams() {
      // this.email = this.$route.query.login;
      this.token = this.$route.query.token;
    }
  },
  created() {
    this.getQueryParams();
  }
};
</script>

<style></style>
